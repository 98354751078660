export default function () {
  return {
    i18nContent: {},
    article: null,
    pages: [],
    relatedArticles: {},
    blogPagination: null,
    blogCategory: undefined,
    blogPosts: [],
    locale: 'fr',
    subscriptions: [],
    features: [],
    postsLiked: [],
    currentEntry: null,
    seo: {
      title: 'HappyCab x VDC',
      meta: {
        title: 'HappyCab x VDC',
        description: 'La plateforme où les cabinets, les entreprises et les candidats les plus cools se rencontrent',
        url: 'https://happycab.fr',
        type: 'website',
        fbAppId: '2165029433804883',
        image: 'platform/branding/default-img-hc.jpg'
      },
      script: {
        ldJson: {
          type: 'application/ld+json',
          innerHTML: '{"@context":"http://schema.org","@type":"WebPage"}'
        }
      }
    },
    analytics: {}
  }
}
