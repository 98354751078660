
const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { name: 'home', path: '', component: () => import('pages/Home.vue') },
      { name: 'searchCompanies', path: '/recherche/entreprises', component: () => import('pages/Search.vue'), meta: { hideFooter: true } },
      { name: 'searchOffers', path: '/recherche/offres-emploi', component: () => import('pages/Search.vue'), meta: { hideFooter: true } },
      { name: 'product', path: '/produit', component: () => import('pages/Static/Product.vue') },
      { name: 'pricing', path: '/tarifs', component: () => import('pages/Static/Pricing.vue') },
      { name: 'changelog', path: '/changelog', component: () => import('pages/Static/Changelog.vue') },
      { name: 'notFound', path: '404', component: () => import('pages/Error404.vue') },
      { name: 'landingRecruit', path: 'recrutement', component: () => import('pages/Static/LandingRecruit.vue') },
      { name: 'landingGAds', path: 'recrutez-un-comptable', component: () => import('pages/Static/LandingGAds.vue') },
      { name: 'applicantProfile', path: 'candidat/:slug', component: () => import('pages/Static/ApplicantProfile.vue') },
      { name: 'knowledgeTools', path: 'outils/:name?', component: () => import('pages/Knowledge/Tools.vue'), meta: { dynamicSEO: true } },

      // Redirects
      { name: 'emailCheck', path: 'inscription/*', component: () => import('pages/Auth/Redirect.vue') },
      { name: 'appRedirectSubscription', path: 'abonnement/:catchAll(.*)*', component: () => import('pages/Auth/Redirect.vue') },
    ]
  },
  {
    path: '/entreprise',
    component: () => import('layouts/CompanyLayout.vue'),
    children: [
      { name: 'companyPage', path: ':slug', component: () => import('pages/Company/Page.vue'), meta: { dynamicSEO: true } },
      { name: 'companyOffers', path: ':slug/offres-emploi', component: () => import('pages/Company/Offers.vue'), meta: { dynamicSEO: true } },
      { name: 'companyOffer', path: ':slug/offre-emploi/:assetSlug', component: () => import('pages/Company/Offer.vue'), meta: { dynamicSEO: true } },
      { name: 'companyLocations', path: ':slug/implantations', component: () => import('pages/Company/Locations.vue'), meta: { dynamicSEO: true } },
      { name: 'testimonialForm', path: ':companySlug/temoignage/:token/:slug?', component: () => import('pages/Auth/Redirect.vue') },
    ]
  },
  {
    path: '/media',
    component: () => import('layouts/MediaLayout.vue'),
    children: [
      { name: 'blog', path: 'blog', component: () => import('pages/Media/Blog.vue'), meta: { seoEntryName: 'media' } },
      { name: 'article', path: 'blog/:slug', component: () => import('pages/Media/Article.vue'), meta: { dynamicSEO: true } },
      { name: 'capsules', path: 'capsules', component: () => import('pages/Media/Capsules.vue'), meta: { seoEntryName: 'media' } },
      { name: 'love-friend', path: 'amours-et-amities', component: () => import('pages/Media/LoveFriend.vue'), meta: { seoEntryName: 'media' } },
      { name: 'lives-compta', path: 'lives-compta', component: () => import('pages/Media/LivesCompta.vue'), meta: { seoEntryName: 'media' } },
    ]
  },

  // Redirections from old links to new ones
  {
    name: 'oldBlog',
    redirect: { name: 'article' },
    path: '/blog/:slug'
  },

  // Always leave these as last ones
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { name: 'redirect', path: 'redirect', component: () => import('pages/Redirect') },
    ]
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
