import { get } from 'lodash'

export function i18nContent (state) {
  return state.i18nContent
}

export function locale (state) {
  return state.locale
}

export function pages (state) {
  return state.pages
}

export function currentEntry (state) {
  return state.currentEntry || null
}

export function landingRecruitAd (state, getters, rootState, rootGetters) {
  const landingRecruitAdId = get(rootState, 'common.config.custom.search.landingRecruitAdId', false)
  const fetchedAds = get(rootState, 'content.platformAds.results', [])
  if (!landingRecruitAdId || !fetchedAds.length) return null
  else return fetchedAds.find(a => a.id === landingRecruitAdId) ?? null
}
