import { createStore } from 'vuex'
import { store } from 'quasar/wrappers'

import asset from './asset'
import auth from './auth'
import common from './common'
import content from './content'
import rating from './rating'
import transaction from './transaction'
import search from './search'
import user from './user'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      asset,
      auth,
      common,
      content,
      rating,
      transaction,
      search,
      user,
    }
  })

  return Store
})
